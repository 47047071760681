@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins-Bold'), url(./Poppins-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: local('OpenSans-Regular'), url(./OpenSans-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans-SemiBold';
    src: local('OpenSans-SemiBold'), url(./OpenSans-SemiBold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: local('OpenSans-Bold'), url(./OpenSans-Bold.ttf) format('truetype');
    font-display: swap;
}
