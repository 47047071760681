* {
    box-sizing: border-box;
}

body {
    margin: 0px;
    padding: 0px;
}

input {
    outline: none;
    appearance: none;
    -webkit-appearance: none;
}

input::-webkit-search-decoration {
    -webkit-appearance: none;
}

iframe {
    display: none;
}

/*.MuiDataGrid-menu .MuiPaper-root, .MuiDataGrid-panel .MuiPaper-root {*/
/*    border-radius: 8px;*/
/*    overflow: hidden;*/
/*    box-shadow: 0 6px 12px #353E5A20;*/
/*}*/
